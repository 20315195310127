// Load Styles
import '../scss/main.scss';

// Load Bootstrap init
import * as bootstrap from 'bootstrap';

import IMask from 'imask';
import validator from 'validator';

// Your app code
document.addEventListener("DOMContentLoaded", function () {
  search();
  filter();
  formHelp();
  formPart();
  mask();
});

function search() {
  let searchElement = document.querySelector("[js-search]");

  if (searchElement !== null) {
    console.log("js-search");
    let number = searchElement.getAttribute("data-number");
    partSearch(number);
  }

  document.addEventListener("click", function (e) {
    if (e.target.hasAttribute("js-part-show")) {
      e.preventDefault();
      let value = e.target.dataset.value;
      console.log(value);

      var elements = document.querySelectorAll('[data-value=' + value + ']');

      elements.forEach(function (element) {
        console.log(element);
        element.classList.remove('d-none');
      });

      e.target.closest("tr").remove();
    }
  });

  function partSearch(number) {
    let xhr = new XMLHttpRequest();
    xhr.open("POST", "/api/ajax/supplier/list", true);
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4 && xhr.status === 200) {
        let response = JSON.parse(xhr.responseText);
        if (response.length) {
          response.forEach(function (value) {
            let xhrPartSearch = new XMLHttpRequest();
            xhrPartSearch.open("POST", "/api/ajax/part/search", true);
            xhrPartSearch.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
            xhrPartSearch.onreadystatechange = function () {
              if (xhrPartSearch.readyState === 4 && xhrPartSearch.status === 200) {
                document.querySelector("[js-search]").innerHTML = xhrPartSearch.responseText;
              }
            };
            xhrPartSearch.send("number=" + number + "&supplier=" + value.id);
          });
        }
      }
    };
    xhr.send("number=" + number);
  }
}

function filter() {
  document.addEventListener("change", function (e) {
    if (e.target.closest("[js-filter] select")) {
      document.querySelector("[js-filter]").submit();
    }
  });
}

function formHelp() {

  const form = document.querySelector("[js-form-help]")

  if (form !== null) {
    if (form.querySelector("input[name=phone]") !== null) {
      const retrievedData = JSON.parse(localStorage.getItem('data'));
      console.log(retrievedData);
      if (retrievedData !== null && retrievedData.phone) {
        form.querySelector("input[name=phone]").value = retrievedData.phone;
      }
    }
    form.addEventListener('submit', event => {
      if (!form.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        const formData = new FormData(form);

        let xhr = new XMLHttpRequest();
        xhr.open("POST", "/api/ajax/form/help", true);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              console.log(xhr.responseText);
            } else {
              console.error("Error: " + xhr.status);
            }
          }
        };
        xhr.send(formData);

        const formDataArray = [];
        formData.forEach((value, key) => {
          formDataArray.push([key, value]);
        });
        const formDataObject = Object.fromEntries(formDataArray);
        console.log(formDataObject);

        if (formDataObject.phone) {
          const dataObject = { phone: formDataObject.phone };
          localStorage.setItem('data', JSON.stringify(dataObject));
        }

        const formThank = new bootstrap.Modal(document.getElementById('formThank'), {})
        formThank.show()
      }

      form.classList.add('was-validated')

      event.preventDefault();
    }, false);
  }
}

function formPart() {

  const formPart = document.querySelector("[js-form-part]")
  const formSupplier = document.querySelector("[js-form-supplier]")

  if (formPart !== null) {
    const modalPart = new bootstrap.Modal(document.querySelector('[js-modal-part]'), {})
    const partBtns = document.querySelectorAll("[js-part-btn]")

    if (formPart.querySelector("input[name=phone]") !== null) {
      const retrievedData = JSON.parse(localStorage.getItem('data'));
      console.log(retrievedData);
      if (retrievedData !== null && retrievedData.phone) {
        formPart.querySelector("input[name=phone]").value = retrievedData.phone;
      }
    }

    formPart.addEventListener('submit', event => {
      if (!formPart.checkValidity()) {
        event.preventDefault()
        event.stopPropagation()
      } else {
        const formData = new FormData(formPart);

        let xhr = new XMLHttpRequest();
        xhr.open("POST", "/api/ajax/form/part", true);
        xhr.onreadystatechange = function () {
          if (xhr.readyState === 4) {
            if (xhr.status === 200) {
              console.log(xhr.responseText);
            } else {
              console.error("Error: " + xhr.status);
            }
          }
        };
        xhr.send(formData);

        modalPart.hide();

        const formDataArray = [];
        formData.forEach((value, key) => {
          formDataArray.push([key, value]);
        });
        const formDataObject = Object.fromEntries(formDataArray);
        console.log(formDataObject);

        if (formDataObject.phone) {
          const dataObject = { phone: formDataObject.phone };
          localStorage.setItem('data', JSON.stringify(dataObject));
        }

        const formThank = new bootstrap.Modal(document.getElementById('formThank'), {})
        formThank.show()
      }

      formPart.classList.add('was-validated')

      event.preventDefault();
    }, false);

    if (partBtns !== null) {
      partBtns.forEach((partBtn) => {
        partBtn.addEventListener('click', event => {
          const part = partBtn.getAttribute('data-part');
          const brand = partBtn.getAttribute('data-brand');
          const price = partBtn.getAttribute('data-price');
          const delivery = partBtn.getAttribute('data-delivery');
          const formData = new FormData();
          formData.append('part', part);
          formData.append('brand', brand);
          formData.append('price', price);
          formData.append('delivery', delivery);

          let xhr = new XMLHttpRequest();
          xhr.open("POST", "/api/ajax/part/info", true);
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const responseObject = JSON.parse(xhr.responseText);
                console.log(responseObject);

                let partInfoHtml = '';
                partInfoHtml += '<div class="fw-semibold fs-5">' + responseObject.data.brand + ' ' + responseObject.data.number + '</div>';
                partInfoHtml += '<div class="mb-2">' + responseObject.data.name + '</div>';
                partInfoHtml += '<div class="row pt-2 border-top">';
                partInfoHtml += '<div class="col">Цена:</div>';
                partInfoHtml += '<div class="col"><b>' + responseObject.data.price + '</b> р.</div>';
                partInfoHtml += '</div>';
                partInfoHtml += '<div class="row">';
                partInfoHtml += '<div class="col">Срок поставки:</div>';
                partInfoHtml += '<div class="col"><b>' + responseObject.data.delivery + '</b> д.</div>';
                partInfoHtml += '</div>';

                const partInfo = formPart.querySelector('[js-part-info]');
                if (partInfo !== null) {
                  partInfo.innerHTML = partInfoHtml;
                }

                const partId = formPart.querySelector('input[name=partId]');
                if (partId !== null) {
                  partId.value = responseObject.data.id;
                }

                modalPart.show();
              } else {
                console.error("Error: " + xhr.status);
              }
            }
          };
          xhr.send(formData);
        }, false);
      });
    }
  }

  if (formSupplier !== null) {
    const modalSupplier = new bootstrap.Modal(document.querySelector('[js-modal-supplier]'), {})
    const supplierBtns = document.querySelectorAll("[js-supplier-btn]")

    if (supplierBtns !== null) {
      supplierBtns.forEach((supplierBtn) => {
        supplierBtn.addEventListener('click', event => {
          const part = supplierBtn.getAttribute('data-part');
          const brand = supplierBtn.getAttribute('data-brand');
          const price = supplierBtn.getAttribute('data-price');
          const delivery = supplierBtn.getAttribute('data-delivery');
          const formData = new FormData();
          formData.append('part', part);
          formData.append('brand', brand);
          formData.append('price', price);
          formData.append('delivery', delivery);

          let xhr = new XMLHttpRequest();
          xhr.open("POST", "/api/ajax/part/supplier", true);
          xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
              if (xhr.status === 200) {
                const responseObject = JSON.parse(xhr.responseText);
                console.log(responseObject);

                let supplierInfoHtml = '';
                supplierInfoHtml += '<div class="fw-semibold fs-5">Доступные поставщики</div>';
                supplierInfoHtml += '<div class="mb-2">' + responseObject.suppliers + '</div>';

                const supplierInfo = formSupplier.querySelector('[js-supplier-info]');
                if (supplierInfo !== null) {
                  supplierInfo.innerHTML = supplierInfoHtml;
                }

                modalSupplier.show();
              } else {
                console.error("Error: " + xhr.status);
              }
            }
          };
          xhr.send(formData);
        }, false);
      });
    }
  }
}

function mask() {
  let phoneInput = document.querySelector("input[name=phone]");

  if (phoneInput) {
    IMask(phoneInput, {
      mask: "+{7}({9}00)000-00-00",
    });
  }
}

